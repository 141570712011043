import { useAuthStore } from '~/store/auth'

export default defineNuxtRouteMiddleware(async (to) => {
  const authStore = useAuthStore()
  const user = useUser()
  const config = useRuntimeConfig()

  // visitor token is not persisted in the localstorage so it only exists in the current session.
  // Disappears after refresh
  if (authStore.visitorToken) {
    return
  }

  const dicToken = to.query.token as string
  const userId = to.query.user_id as string

  if (dicToken) {
    try {
      const response = await fetch(`${config.public.DIC_BASE_URL}auth/user-token/${userId}`)
      const data = await response.json()
      if (data.token !== dicToken) return navigateTo('/error')

      authStore.setVisitorToken(dicToken)
      navigateTo(to.path)
      return
    } catch (error) {
      return navigateTo('/')
    }
  }

  if (!user) {
    if (to) {
      const redirectTo = useCookie('redirectTo')
      redirectTo.value = to.path
    }
    return navigateTo('/login')
  }

  if (!user?.phone && to.path !== '/profile/setup') {
    return navigateTo('/profile/setup')
  }

  if (to.meta.permissions) {
    const routePermissions = to.meta.permissions as string[]

    if (!useHasPermissions(routePermissions)) {
      return navigateTo('/')
    }
  }
})
